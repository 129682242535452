import React, { useMemo, useEffect } from 'react';
import { filterTruthy } from '@utils/misc';
import { ENTITY_INFO_ITEMS } from '@constants/entity';
import { getEntityProperty } from '@utils/entity';
import Card from '@components/common/Card/Card';
import { EntityDetails } from '@infrastructure/redux/inventoryPage';
import { hasAtLeastOneProperty } from '@utils/entity';
import { InfoGrid, InfoGridItem, InfoGridProps } from '@components/layout/InfoGrid/InfoGrid';
import styles from './OverviewTab.module.scss';
import CopyText from '@components/typography/CopyText';
import { CooldownButton } from '@components/common/CooldownButton';
import Icon from '@components/common/Icon';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import ROUTES from '@infrastructure/routes';
import { selectTotalIssueCount } from '@infrastructure/redux/issues';
import { useAppSelector } from '@infrastructure/redux/store';
import { apiClient } from '@infrastructure/api';
import { useQueryClient } from 'react-query';
import { useAppDispatch } from '@infrastructure/redux/store';
import { setNotification } from '@infrastructure/redux/notifications';
import ENDPOINTS from '@infrastructure/api/endpoints';
import {
  selectItemsAsArray,
  selectPagination,
  selectQueryParams,
  updateItems,
  updatePagination,
} from '@redux/logbook';
import { RemoteStoredTableProps, RemoteStoredTable } from '@components/common/Table';
import { getTableColumns } from '@components/pages/Logbook/components/utils';
import { useLogbookQuery, Note } from '@infrastructure/api/BaseNClient/useLogbookQuery';
import { EntitiesResponse, useEntitiesQuery } from '@infrastructure/api/BaseNClient/useEntitiesQuery';
import { toIdMap } from '@utils/misc';
import { uniq } from 'lodash';
import Error500Page from '@components/pages/Error500Page';

interface OverviewTabProps {
  entity: EntityDetails;
}

export const OverviewTab: React.FC<OverviewTabProps> = ({ entity, ...props }) => {
  const shouldShowInfo = hasAtLeastOneProperty(entity);
  const navigate = useNavigate();
  const [loadingStates, setLoadingStates] = React.useState({
    backup: false,
    terminal: false,
    compare: false
  });
  const setLoadingState = (key: keyof typeof loadingStates, value: boolean) => {
    setLoadingStates(prev => ({ ...prev, [key]: value }));
  };
  const totalIssueCount = useAppSelector(selectTotalIssueCount);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const items = useAppSelector(selectItemsAsArray);
  const params = useAppSelector(selectQueryParams);
  const pagination = useAppSelector(selectPagination);

  const notesQuery = useLogbookQuery({
    ...params,
    entity_ids: entity.id,
  });

  const entitiesQuery = useEntitiesQuery<EntitiesResponse<EntityDetails>>(
    {
      ids: uniq(notesQuery?.data?.notes?.map(note => note.entityid)).join(',') ?? '',
    },
    { enabled: !!notesQuery.data?.notes?.length }
  );

  useEffect(() => {
    if (notesQuery.data?.notes && entitiesQuery.data?.entities) {
      const entityMap = toIdMap(entitiesQuery.data.entities);
      const items = notesQuery.data.notes.map(note => ({
        ...note,
        entity: entityMap[note.entityid],
      }));
      dispatch(updateItems(items))
      dispatch(updatePagination({ page: 1, rowsPerPage: 10 }))
    }
  }, [notesQuery.data, dispatch, entitiesQuery.data?.entities]);

  const tableProps: RemoteStoredTableProps<Note> = useMemo(
    () => ({
      name: 'logbook',
      items,
      columns: getTableColumns(),
      config: {
        ...pagination,
        selectable: false,
        bordered: false,
        sortable: false,
        paginated: false,
        hasToolbar: false,
      }
    }),
    [
      items,
      pagination,
      getTableColumns,
    ]
  );

  if (notesQuery.isError || entitiesQuery.isError) {
    return <Error500Page />;
  }

  const infoData = useMemo(
    () =>
      entity
        ? filterTruthy([
          ...Object.keys(ENTITY_INFO_ITEMS).reduce((cols, key) => {
            const value = getEntityProperty(entity, key);
            if (value !== undefined) {
              cols.push({
                label: ENTITY_INFO_ITEMS[key],
                value,
              })
            }
            return cols;
          }, [] as InfoGridProps['data']),
        ])
        : [],
    [entity]
  );

  const renderHeader = () => {
    return (
      <>
        <CopyText variant="copy-4">Information</CopyText>
      </>
    );
  };

  const renderMetrics = useMemo(() => {
    return entity.metricsets.filter(metric =>
      metric.metrics.length > 0).map(metric => (
        <Card key={metric.id} additionalClass="mb-12 h-36" noPadding>
          <CopyText variant='copy-1' additionalClass='p-4'>{metric.name}</CopyText>
        </Card>
      ))
  }, [entity.metricsets]);

  const handleError = (action: string) => (error: any) => {
    dispatch(setNotification({ type: 'error', title: `Error ${action}: ${error.message}` }));
  };

  const handleBackupClick = React.useCallback(() => {
    setLoadingState('backup', true)
    queryClient
      .fetchQuery(['backup'], () =>
        apiClient.get<{ result: string }>(ENDPOINTS.queueConfigForBackup.replace(':entityId', entity.id))
      )
      .then(({ result }) => {
        if (result.toLocaleLowerCase() === 'ok') {
          dispatch(setNotification({ type: 'success', title: `Device has been queued for backup` }));
        } else {
          dispatch(setNotification({ type: 'error', title: `Error: ${result}` }));
        }
      })
      .catch((error: any) => {
        handleError('opening terminal')(error);
      })
      .finally(() => {
        setLoadingState('backup', false)
      });
  }, [dispatch, entity.id, queryClient]);

  const handleOpenTerminal = React.useCallback(() => {
    setLoadingState('terminal', true)
    try {
      navigate(ROUTES.terminal.replace(':entityId', entity.id));
    } catch (error: any) {
      handleError('opening terminal')(error);
    } finally {
      setLoadingState('terminal', false)
    }
  }, [entity.id, navigate, dispatch]);

  const handleCompareConfig = React.useCallback(() => {
    setLoadingState('compare', true)
    try {
      navigate('/config-manager/diff');
    } catch (error: any) {
      handleError('opening terminal')(error);
    } finally {
      setLoadingState('compare', false)
    }
  }, [navigate, dispatch]);

  return shouldShowInfo ? (
    <>
      <div style={{ display: 'flex', maxHeight: '100%' }}>
        <div style={{ width: `30%`, display: 'flex' }}>
          <Card header={renderHeader()} additionalClass={styles.grid} noPadding>
            <InfoGrid
              data={infoData}
              {...props}
            />
          </Card>
        </div>
        <div style={{ marginLeft: '1.5%', minWidth: '50%', maxWidth: '70%' }}>
          <Card header={<CopyText variant='copy-2'>Metrics</CopyText>} additionalClass="h-full">
            {renderMetrics}
          </Card>
        </div>
        <div style={{ marginLeft: '1.5%', minWidth: '18%', maxWidth: '18%' }}>
          <Card additionalClass="mb-16">
            <CopyText variant="copy-3">New Alerts<Icon name="NotificationsFill" additionalClass='float-right' /></CopyText>
            {entity?.alert_summary.other.alerts.length}
            <CopyText variant="copy-4">{entity?.alert_summary.other.alerts.color}</CopyText>
          </Card>
          <Card additionalClass="mb-16">
            <CopyText variant="copy-3">New Issues<Icon name="Issues" additionalClass='float-right' /></CopyText>
            {totalIssueCount}
            <CopyText variant="copy-4">{entity?.alert_summary.main.alerts}</CopyText>
          </Card>
          <Card header={<CopyText variant='copy-2'>Configuration</CopyText>} additionalClass="h-fitcontent">
            <CooldownButton
              name={`run-backup-${entity.id}`}
              variant="fillOutline"
              additionalClass="w-full mb-10"
              size="m"
              onClick={handleBackupClick}
              disabled={loadingStates.backup}
              cooldown={60 * 2}
            >
              {loadingStates.backup ? <LoadingSpinner centered size="m" /> : <Icon name="Reset" additionalClass="mr-8" />}
              Run Backup
            </CooldownButton>
            <CooldownButton
              name={`run-backup-${entity.id}`}
              variant="fillOutline"
              additionalClass="w-full mb-10"
              size="m"
              onClick={handleCompareConfig}
              cooldown={0}
            >
              {loadingStates.compare ? <LoadingSpinner centered size="m" /> : <Icon name="diff" additionalClass="mr-8" />}
              Compare Configs
            </CooldownButton>
            <CooldownButton
              name={`run-backup-${entity.id}`}
              variant="fillOutline"
              additionalClass="w-full"
              size="m"
              onClick={handleOpenTerminal}
              cooldown={0}
            >
              {loadingStates.terminal ? <LoadingSpinner centered size="m" /> : <Icon name="Terminal" additionalClass="mr-8" />}
              Open Terminal
            </CooldownButton>
          </Card>
        </div>
      </div >
      <Card header={<CopyText variant="copy-4">Last 10 logbook entries</CopyText>} additionalClass="w-full mt-16 mb-16" noPadding>
        <div className="h-full vbox">
          <RemoteStoredTable
            {...tableProps}
          />
        </div>
      </Card>
    </>
  ) : null;
};
