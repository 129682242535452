import { BaseSelectItem } from '@components/common/form/Select';
import React, { FC } from 'react';
import c from 'classnames';

import styles from './InfoGrid.module.scss';

export type InfoGridItem = BaseSelectItem<string | React.ReactNode>;

export type InfoGridProps = {
  size?: 'xs' | 'm' | 's' | 'l';
  data: Array<InfoGridItem>;
  variant?: 'dotted' | 'bordered' | 'description';
  additionalClass?: string;
};

export const InfoGrid: FC<InfoGridProps> = ({ data, size = 'm', variant = 'bordered', additionalClass }) => {
  return (
    <div
      className={c(
        styles.infoGrid,
        {
          [styles.large]: size === 'l',
          [styles.medium]: size === 'm',
          [styles.small]: size === 's',
          [styles.extraSmall]: size === 'xs',
        },
        styles[variant],
        additionalClass
      )}
    >
      {data.map(({ label, value }, i) => (
        <React.Fragment key={i.toString()}>
          <div className={styles.gridLabel}>{label}</div>
          <div>
            <span className={styles.wordWrap}>{value}</span>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};
